
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function collapsibleVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.firstSelectedTerm || '',
                    'disabled': this.disabled
                }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                    'value': '',
                    'key': 'null-option'
                }, '\n        ', window.themeSettings.locales.ymm[this.title.toLowerCase().replace(' ', '_')] || this.title, '\n      ') : null, this.loading ? _createElement('option', {
                    'key': 'loading-option',
                    'disabled': true
                }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                    _createElement('option', {
                        'className': 'cm_option_title',
                        'disabled': true,
                        'key': '6511'
                    }, 'Popular Makes'),
                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                    _createElement('option', {
                        'className': 'cm_option_title',
                        'disabled': true,
                        'key': '6514'
                    }, 'All Makes'),
                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null)));
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.firstSelectedTerm || '',
                    'disabled': this.disabled
                }, !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                    'value': '',
                    'key': 'null-option'
                }, '\n        ', window.themeSettings.locales.ymm[this.title.toLowerCase().replace(' ', '_')] || this.title, '\n      ') : null, this.loading ? _createElement('option', {
                    'key': 'loading-option',
                    'disabled': true
                }, '...loading...') : null, this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                    _createElement('option', {
                        'className': 'cm_option_title',
                        'disabled': true,
                        'key': '6511'
                    }, 'Popular Makes'),
                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                    _createElement('option', {
                        'className': 'cm_option_title',
                        'disabled': true,
                        'key': '6514'
                    }, 'All Makes'),
                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                ] : null, this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null)));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home cm_vehicle-widget__header cm_vehicle-widget__collapsible container' }, this.template === 'locked' ? _createElement('div', {
        'className': 'cmTemplate_locked',
        'key': '125'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_header' }, _createElement('span', {
        'className': 'cm_vehicle-widget_header-label',
        'onClick': this.changeVehicle
    }, '\n        ', this.vehicleString, '\n      '), [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle down',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '3780'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))], _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_btn button button--small button--primary',
        'onClick': this.changeVehicle,
        'role': 'button',
        'tabIndex': '0'
    }, '\n          ', window.themeSettings.locales.ymm.change || 'Change', '\n        '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_btn button button--small button--secondary',
        'onClick': this.discardVehicle,
        'role': 'button',
        'tabIndex': '0'
    }, '\n          ', window.themeSettings.locales.ymm.clear || 'Clear', '\n        ')))) : null, this.template === 'active' ? _createElement('div', {
        'className': 'cmTemplate_active',
        'key': '1237'
    }, !this.withCollapsing ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns',
        'key': '1318'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.themeSettings.locales.ymm.select || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_btn button button--small button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ', window.themeSettings.locales.ymm.go || 'GO', '\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_btn button button--small button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, '\n    ', window.themeSettings.locales.ymm.clear || 'Clear', '\n  ')))) : null, this.withCollapsing ? _createElement('div', {
        'className': 'cm_vehicle-widget_header',
        'onClick': this.toggleCollapsed,
        'key': '5627'
    }, _createElement('span', { 'className': 'cm_vehicle-widget_header-label' }, !this.isVehicleSelected ? [_createElement('h3', {}, window.themeSettings.locales.ymm.home_select || 'Select Your Vehicle')] : null, this.isVehicleSelected ? [this.vehicleString] : null), !this.collapsed ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle up',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '60410'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, this.collapsed ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle down',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '63610'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null) : null, this.withCollapsing ? _createElement('div', {
        'className': 'cm_collapsible-elem',
        'key': '6691'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_btn button button--small button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ', window.themeSettings.locales.ymm.go || 'GO', '\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_btn button button--small button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, '\n    ', window.themeSettings.locales.ymm.clear || 'Clear', '\n  '))))) : null) : null);
}
        export const componentNames = []